exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bookmarks-js": () => import("./../../../src/pages/bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-panels-js": () => import("./../../../src/pages/panels.js" /* webpackChunkName: "component---src-pages-panels-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-all-js": () => import("./../../../src/templates/PostsAll.js" /* webpackChunkName: "component---src-templates-posts-all-js" */),
  "component---src-templates-posts-tagged-js": () => import("./../../../src/templates/PostsTagged.js" /* webpackChunkName: "component---src-templates-posts-tagged-js" */)
}

